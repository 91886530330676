.question-modal {
  .podcast-question-table {
    min-height: 30vh;
  }
  .assets-wrapper {
    padding: 0;
    .ant-table-wrapper .ant-table {
      background: none;
      thead {
        .ant-table-cell {
          line-height: 40px;
        }
      }
      tbody {
        .ant-table-cell {
          line-height: 30px;
        }
      }
    }
  }
}
