/*css index start here
  (1) typography responsive
  (2) category responsive view
css index end here*/

/*responsive typography css start here*/
.responsive-view {
  @media (min-width: 991px) {
    --tq-primary-font: 'Founders Grotesk';
    --tq-primary-font-light: 'Founders Grotesk light';
    --tq-primary-font-mono: 'Founders Grotesk Mono';
    --tq-secondary-font: 'Sanomat';
    --tq-display-h0: normal 300 60px/72px var(--tq-secondary-font);
    --tq-display-h1: normal 300 40px/48px var(--tq-secondary-font);
    --tq-display-h2: normal 400 32px/38px var(--tq-secondary-font);
    --tq-display-h3: normal 400 28px/34px var(--tq-secondary-font);
    --tq-display-h4: normal 400 24px/29px var(--tq-secondary-font);
    --tq-display-h5: normal 400 20px/24px var(--tq-secondary-font);
    --tq-display-h6: normal 400 20px/24px var(--tq-secondary-font);

    --tq-heading-h1: normal 500 40px/47px var(--tq-primary-font);
    --tq-heading-h2: normal 500 32px/38px var(--tq-primary-font);
    --tq-heading-h3: normal 500 28px/33px var(--tq-primary-font);
    --tq-heading-h4: normal 500 24px/28px var(--tq-primary-font);
    --tq-heading-h5: normal 500 20px/24px var(--tq-primary-font);
    --tq-heading-h6: normal 500 18px/21px var(--tq-primary-font);
    --tq-heading-h7: normal 500 16px/19px var(--tq-primary-font);

    --tq-text-lg: normal 400 18px/1 var(--tq-primary-font);
    --tq-text-lg-bold: normal 500 18px/1 var(--tq-primary-font);

    --tq-text-md: normal 400 16px/1 var(--tq-primary-font);
    --tq-text-md-bold: normal 500 16px/1 var(--tq-primary-font);
    --tq-text-md-kicker: normal 400 18px/1 var(--tq-primary-font-mono);

    --tq-text-small: normal 400 14px/1 var(--tq-primary-font);
    --tq-text-small-bold: normal 500 14px/1 var(--tq-primary-font);
    --tq-text-small-kicker: normal 400 12px/1 var(--tq-primary-font-mono);

    --tq-text-tiny: normal 400 12px/14px var(--tq-primary-font);
    --tq-text-tiny-bold: normal 500 12px/14px var(--tq-primary-font);
    --tq-text-tiny-kicker: normal 400 10px/1 var(--tq-primary-font-mono);

    --tq-button-xl: normal 500 22px/1 var(--tq-primary-font);
    --tq-button-l: normal 500 18px/1 var(--tq-primary-font);
    --tq-button-m: normal 500 16px/1 var(--tq-primary-font);
    --tq-button-s: normal 500 14px/1 var(--tq-primary-font);

    --tq-label-xl: normal 600 20px/110% var(--tq-primary-font);
    --tq-label-l: normal 600 18px/110% var(--tq-primary-font);
    --tq-label-m: normal 600 16px/110% var(--tq-primary-font);
    --tq-label-s: normal 600 14px/110% var(--tq-primary-font);
    --tq-label-xs: normal 600 12px/110% var(--tq-primary-font);

    --tq-paragraph-s: normal 400 14px/140% var(--tq-primary-font);
    --tq-paragraph-m: normal 400 16px/140% var(--tq-primary-font);
    --tq-paragraph-l: normal 400 18px/140% var(--tq-primary-font);
    --tq-paragraph-l-xl: normal 400 24px/140% var(--tq-primary-font);

    --tq-quote: normal 400 32px/140% var(--tq-primary-font);
  }
}

/*responsive typography css end here*/

/*category responsive view css start here*/
.responsive-view {
  .section-header .sh-top .sh-title {
    font: @tq-heading-h6;
  }

  .category-card .category-title .topic::before {
    border-bottom: 4px solid;
  }

  &.category-card {
    .category-title .topic {
      font: @tq-display-h5;
      padding-bottom: 12px;

      &::before {
        border-bottom: 4px solid;
      }
    }
  }

  .category-card.categorylist .category-title {
    padding: 24px 12px;
  }

  &.category-grid {
    .category-card {
      padding-top: 104px;

      .category-title .topic {
        .display-h5;
      }
    }
  }

  &.category-slider {
    .slick-slide {
      margin: 0 6px;
      width: auto;
    }

    .category-card {
      max-width: 160px;
      min-width: 160px;
      padding-top: 208px;

      .category-title {
        padding: 16px;
      }
    }
  }

  &.category-card.category-lending {
    padding-top: 160px;

    .category-title {
      padding: 16px;

      .topic {
        .display-h3;
      }
    }
  }

  &.category-list {
    .category-card {
      padding-top: 84px;
      display: block;

      .category-title {
        .topic {
          .display-h4;
          padding-bottom: 12px;
        }
      }
    }
  }
}

/*category responsive view css end here*/
/*collection responsive view css start here*/
.responsive-view {
  .collection-card {
    max-width: 167px;
    min-width: 167px;
  }

  &.collection-hero {
    padding: 32px 32px 24px 32px;

    .container {
      padding: 0;
    }

    .collection-hero-wrapper {
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;

      .collection-hero-description {
        .collection-hero-title {
          .display-h3;
        }

        p {
          .text-md;
        }
      }
    }
  }
}

/*collection responsive view css end here*/

/*cta module responsive view css start here*/
.responsive-view {
  &.banner {
    padding-top: 240px;
  }
}

.ctacard {
  &.responsive-view {
    padding: 32px 24px;
    max-width: 375px;
    flex-direction: column;
    margin: 0;

    border-radius: 0;

    .ctaCard-img {
      max-width: 100%;
      margin-bottom: 24px;
      padding-top: 184px;
      min-height: 0;
      border-radius: 8px;
      overflow: hidden;
    }

    .ctacard-dec {
      max-width: 100%;
      text-align: center;
      margin: 0;
      padding: 0;

      h3 {
        .display-h3;
      }

      .paragraph {
        margin-bottom: 24px;
        .paragraph-m;
      }
    }

    .cta-button-wrapper .cs-button.ant-btn.ant-btn-sm {
      height: 40px;
    }
  }
}

.responsive-view {
  &.ctacard.ctaMarketing {
    border-radius: 0;

    .ctacard-dec {
      padding: 0;

      .date {
        .heading-h6;
        .mb-4;
      }

      .cta-button-wrapper {
        gap: 8px;

        button {
          flex: 1;
          padding: 0;
        }
      }
    }
  }
}

/*cta module responsive view css end here*/
/*featured video responsive view  css start here*/
.responsive-view {
  &.featured-carousel {
    padding: 0;
    background: @tq-neutral-900;

    .slick-slide .featured-video-card {
      opacity: 1;
      transform: scale(1);
      -webkit-transform-origin-x: unset;
    }
  }

  &.featured-carousel {
    .slick-slide.slick-current .featured-video-card {
      padding-top: 0;
    }

    .slick-slide .featured-video-card {
      padding-top: 0;
    }

    &.center-false {
      overflow: hidden;
      padding: 24px 16px 0 16px;

      &:after {
        content: '';
        background: rgba(0, 0, 0, 0.65);
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
      }

      &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background-image: url('../../../assets/images/blur.png');
        filter: blur(80px);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    .display-h4 {
      color: #fbf7f1;
      margin-bottom: 12px;
      position: relative;
      z-index: 1;
    }

    &.center-false .ant-carousel .fvc-details {
      background: transparent;
      padding: 16px 0;
    }

    &.center-false .ant-carousel .fvc-image {
      height: 0;
      padding-top: 56%;
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &.center-false .ant-carousel .fvc-details .fvc-description {
      margin-top: 0;
    }

    .featured-video-card {
      padding-top: 0;
    }

    .fvc-image {
      .lazy-load-image {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  .slick-slide .featured-video-card .paragraph-m {
    display: block;
  }

  .slick-slide.slick-current .featured-video-card {
    opacity: 1;
    transform: scale(1);
  }

  .featured-video-card {
    flex-direction: column;
  }

  .ant-carousel {
    .featured-video-card .fvc-image::before {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    }

    .featured-video-card .fvc-image {
      position: relative;
      height: 420px;
    }

    .featured-video-card .fvc-details {
      padding: 0 16px 24px 16px;
      background: @tq-neutral-1000;
      flex-direction: column;
      position: relative;
    }

    .featured-video-card .fvc-details .fvc-description {
      max-width: 100%;
      margin-top: -146px;
      position: relative;
    }
    .featured-video-card .fvc-details .article-description {
      margin-top: 0px;
    }

    .featured-video-card .fvc-details .slider-button {
      max-width: 100%;
      display: flex;
      flex-direction: row-reverse;
      gap: 8px;
    }

    .featured-video-card .fvc-details .slider-button button {
      flex: 1 1;
      width: 50%;
      height: 48px;
      font: @tq-text-small-bold;
    }

    .featured-video-card .fvc-details .slider-button button:last-child {
      margin-top: 0;
    }

    .featured-video-card {
      padding: 0;
      flex-direction: column;
      border-radius: 0;
    }
  }
}

/*featured video responsive view  css end here*/

/*video module responsive view css start here*/
/*video grid*/
.video-grid {
  &.responsive-view {
    .section-header .sh-top .sh-title {
      font: @tq-heading-h6;
    }

    .ant-tag {
      height: 21px;
      min-height: 21px;
      max-height: 21px;
      gap: 0;
      padding: 0 8px;
      padding-right: 4px;
    }

    .video-card .video-image {
      .timestamp {
        height: 16px;
        line-height: 16px;
        font-size: 12px;
        padding: 0 6px;
      }
    }
  }
}

/*video grid*/
/*video list*/
.video-list-grid {
  &.responsive-view {
    .videolist {
      padding: 16px 0;
    }

    .section-header .sh-top .sh-title {
      font: @tq-heading-h6;
    }

    .video-card.videolist .video-image {
      width: 104px;
    }

    .ant-tag {
      height: 21px;
      min-height: 21px;
      max-height: 21px;
      gap: 0;
      padding: 0 8px;
      padding-right: 4px;
    }

    .video-card .video-image .timestamp {
      height: 16px;
      line-height: 16px;
      font-size: 12px;
      padding: 0 6px;
    }
  }
}

/*video list*/
/*video slider*/
.video-carousel {
  &.responsive-view {
    .slick-slide {
      margin: 0 4px;
      width: auto;
    }

    .video-card {
      max-width: 160px;
      min-width: 160px;
    }

    .section-header .sh-top .sh-title {
      font: @tq-heading-h6;
    }

    .ant-tag {
      height: 21px;
      min-height: 21px;
      max-height: 21px;
      padding: 0 8px;
      gap: 0;
      padding-right: 4px;
    }

    .video-card .video-image .timestamp {
      height: 16px;
      line-height: 16px;
      font-size: 12px;
      padding: 0 6px;
    }
  }
}

/*video slider*/
/*video module responsive view css end here*/
/*tage grid and slider responsive view css start here*/
.responsive-view {
  &.tag_grid {
    .ant-tag {
      .text-small;
    }
  }

  &.tag_carousel {
    .ant-tag {
      .text-small;
    }
  }

  &.taghero {
    padding: 63px 24px;

    .title {
      .display-h3;
    }
  }
}

/*tag */
.responsive-view {
  .cs-button.ant-btn.ant-btn-lg {
    height: 50px;
    .button-m;
  }
}

.responsive-view {
  &.heroblock {
    padding-top: 131px;
    padding-bottom: 101px;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        360deg,
        #000000 0%,
        rgba(0, 0, 0, 0.876677) 48.8%,
        rgba(0, 0, 0, 0) 96.6%
      );
      height: 412px;
      width: 100%;
      z-index: 1;
    }

    .container {
      padding: 0 16px;
    }

    .herocontent {
      text-align: left;

      .title {
        .display-h1;
        overflow: hidden;
        white-space: pre-wrap;
      }

      .description {
        .paragraph-l;
        overflow: hidden;
        white-space: pre-wrap;
      }

      .date-heading {
        .heading-h5;
        margin-top: 16px;
      }

      .hero-button-wrapper {
        gap: 12px;

        button {
          padding: 0;
          flex: 1;
        }
      }
    }
  }
}

.responsive-view {
  &.information-block {
    padding: 60px 0;

    .information-wrapper {
      flex-direction: column-reverse;
      gap: 32px;

      &.info-rev {
        flex-direction: column-reverse;
      }

      .information-image {
        padding-top: 195px;
        filter: none;

        &.square-img {
          padding-top: 290px;
        }

        &.portrait-img {
          padding-top: 350px;
        }
      }

      .information-content {
        h3 {
          .display-h3;
          margin-bottom: 8px;
        }

        .button-wrapper {
          gap: 12px;
          flex-wrap: wrap;

          button {
            padding: 0;
            flex: 1;
          }
        }

        .description {
          .paragraph-m;
        }
      }
    }
  }
}

/*newsletter css start here*/
.responsive-view {
  &.newsletter-section {
    padding: 60px 16px;

    .newsletter-wrapper {
      .title {
        .display-h3;
        .mb-4;
      }

      .description {
        .paragraph-m;
      }

      .subscribe-group {
        flex-direction: column;
        margin-top: 32px;
        gap: 2px;

        .form-group .ant-input {
          max-height: 44px;
          font-size: 18px;
        }
      }
    }
  }
}

/*newsletter css end here*/

/*marketing donate module css start here*/
.responsive-view {
  &.donate-wrapper {
    padding: 60px 16px;

    .d-title {
      .display-h3;
      margin-bottom: 10px;
    }

    .amount-wrapper {
      margin-top: 20px;
      flex-direction: column;
      box-shadow: unset;

      .amount-video-section {
        width: 100%;
        flex: 0 0 100%;
        padding: 16px;

        .video-section-title {
          margin-bottom: 26px;

          h3 {
            .display-h4;
            margin-bottom: 16px;
          }

          p {
            .paragraph-m;
          }
        }

        .video-block.video-flex {
          gap: 12px;
        }

        .video-block.video-flex .video-img {
          max-width: 104px;
        }

        .video-block.video-flex p {
          .paragraph-s;
        }

        .video-block {
          .video-img {
            margin-bottom: 12px;
          }

          p {
            .paragraph-s;
          }
        }
      }

      .amount-form {
        width: 100%;
        flex: 0 0 100%;
        padding: 16px;

        .cs-radiogroup .ant-radio-group .ant-radio-button-wrapper span {
          .button-m;
        }

        .form-label {
          .heading-h7;
        }

        .cs-radio-form {
          margin-bottom: 24px;
        }

        .cs-devider {
          max-width: 240px;

          .ant-divider {
            margin: 20px auto;
          }
        }

        .cs-radiogroup .ant-radio-group {
          gap: 12px;
        }
      }
    }
  }
}

/*marketing donate module css end here*/

/*faq section css start here*/
.responsive-view {
  &.faq-section {
    padding: 32px 16px 16px;

    .faq-section-title {
      margin-bottom: 16px;

      .title {
        .display-h4;
      }

      .subtitle {
        .paragraph-l;
      }
    }
  }

  .cs-accordion
    .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-header-text {
    .heading-h6;
  }
}

/*faq section css end here*/

/*cta banner view css start here*/
.responsive-view {
  &.ctaBanner-section {
    padding: 0;

    .cta-bnnner-block {
      &.ctabannerbackground-none {
        padding: 60px 16px;
      }

      &.ctabannerbackground {
        border-radius: 0;
        padding: 60px 16px;
      }

      .cta-content {
        .title {
          .display-h5;
          .mb-8;
        }

        .description {
          .paragraph-m;
          .mb-24;
        }
      }
    }
  }
}

/*cta banner view css end here*/

/*feature collection css start here*/
.responsive-view {
  &.featured-collection {
    .featured-collection-wrapper {
      flex-direction: column;
      padding: 32px 24px;
      border-radius: 0;

      .featured-collection-img {
        width: 100%;
        padding-top: 180px;
        border-radius: 8px;
        overflow: hidden;
      }

      .featured-collection-dec {
        padding: 24px 0 0 0;

        .title {
          .display-h3;
          margin-bottom: 2px;
        }

        .featured-collection-video-count {
          .text-md;
        }

        p {
          .paragraph-m;
        }

        button {
          width: 100%;
          margin-top: 24px;
        }
      }
    }
  }
}

/*feature collection css end here*/

/*contact us component  css start here*/
.responsive-view {
  &.login-wrapper.contact-page {
    padding: 0;

    .loginblock {
      border-radius: 0;
      .bg-ivory;
      padding: 32px 16px;

      button.cs-button.ant-btn.ant-btn-lg {
        .button-l;
        padding: 0 16px;
      }
    }
  }
}

/*contact us component  css start here*/
/*responsive view video player css start here*/
.responsive-view {
  &.video-info-section {
    & > div {
      & > img {
        width: 100%;
      }
    }
    .custom-overlay-without-controls {
      padding: 0 16px;
    }

    .custom-control-wrap {
      padding: 16px;
    }

    .controlitem-top svg {
      width: 24px;
      height: auto;
    }

    .custom-control-wrap .volume-section {
      display: none;
    }

    .right-controls svg {
      margin-left: 8px;
      width: 24px;
      height: auto;
    }

    .custom-control-wrap .play-duration .text-md {
      font: var(--tq-text-tiny);
    }

    .video-info-wrapper {
      flex-direction: column;
    }

    .video-info-wrapper .videoinfo-title {
      order: 1;
      width: 100%;
      gap: 12px;
    }

    .video-button-wrapper {
      width: 100%;
      justify-content: flex-start;
      order: 5;
    }

    .video-button-wrapper .videoaction svg {
      width: 16px;
      height: 16px;
    }

    .video-button-wrapper .videoaction {
      min-width: 62px;
      max-height: 50px;
      flex: 1 1;
      flex-wrap: wrap;
    }

    .video-avatar-group {
      order: 2;
    }

    .video-info-tags {
      width: 100%;
      order: 3;
      margin: 0 -16px;
      padding-left: 16px;
    }

    .video-info-wrapper p {
      order: 4;
    }
  }
}

/*responsive view video player css end here*/
.responsive-view .one-side-carousel .slick-list {
  padding: 0;
}
.responsive-view {
  .featured-video-card.article-card {
    overflow: hidden;
    padding: 24px 16px 0 16px;
    .fvc-image {
      height: 0;
      padding-top: 56%;
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      z-index: 1;
      img {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
      }
      &::before {
        display: none;
      }
    }
    .fvc-details {
      background: transparent;
      padding: 16px 0;
      position: relative;
      flex-direction: column;
      .slider-button {
        max-width: 100%;
        display: flex;
        gap: 8px;
        button {
          flex: 1 1;
          display: flex;
          font-size: 14px;
          flex-direction: column;
          height: auto;
          padding: 8px 0;
          margin: 0;
        }
      }
    }
  }
}
.responsive-view {
  &.video-list-grid.article-list-grid {
    .section-header {
      padding: 16px;
    }
    .videolist {
      padding: 16px;
      .video-list-action {
        ul {
          list-style: none;
          display: flex;
          flex-wrap: wrap;
          li {
            display: flex;
            align-items: baseline;
            &:not(:last-child)::after {
              content: '';
              height: 6px;
              width: 6px;
              border-radius: 50%;
              background: var(--tq-black-70);
              margin: 0 8px;
              display: inline-block;
              opacity: 0.6;
            }
            a {
              color: var(--tq-black-90);
              font: @tq-button-s;

              &:hover {
                color: var(--tq-red);
              }
            }
          }
        }
      }
    }
  }
}
