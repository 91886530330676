.plan-form {
  .amount-input {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .view-plan-permissions {
    min-width: 230px;
    max-width: 250px;
    width: 100%;
    p {
      margin: 8px;
      display: flex;
    }
    span {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      max-width: 100px;
      margin-left: 6px;
    }
  }
}
