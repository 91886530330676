@import url('../variable.less');

.featured-video-card {
  padding-top: 37.5%;
  min-height: 410px;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: flex-end;

  @media @screen767 {
    padding: 0;
    flex-direction: column;
    border-radius: 0;
  }

  &.featured-carousel.center-false {
    .fvc-image {
      &::before {
        content: '';
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 4.55%,
          rgba(0, 0, 0, 0.85) 100%
        );
        height: calc(100% - 86px);
        bottom: 0;
        left: 0;
        width: 100%;
        position: absolute;
        z-index: 1;
        display: block;

        @media @screen767 {
          display: none;
        }
      }
    }
  }

  .fvc-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    @media @screen767 {
      position: relative;
      height: 420px;
    }

    &::before {
      content: '';
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 4.55%,
        rgba(0, 0, 0, 0.85) 100%
      );
      height: calc(100% - 86px);
      bottom: 0;
      left: 0;
      width: 100%;
      position: absolute;
      z-index: 1;

      @media @screen767 {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      }
    }
  }

  .tag_grid {
    @media @screen767 {
      margin-bottom: 12px;
    }
  }

  .fvc-details {
    padding: 0 40px 32px 40px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: absolute;
    z-index: 2;

    @media @screen767 {
      padding: 0 16px 24px 16px;
      background: @tq-neutral-1000;
      flex-direction: column;
      position: relative;
    }

    .slider-button {
      max-width: 244px;
      width: 100%;
      margin-bottom: 8px;

      @media @screen767 {
        max-width: 100%;
        display: flex;
        flex-direction: row-reverse;
        gap: 8px;
      }

      button {
        font-size: 18px;

        @media @screen767 {
          flex: 1;
          width: 50%;
          height: 48px;
          font: @tq-button-m;
        }

        svg {
          @media @screen767 {
            width: 20px;
            flex-shrink: 0;
          }
        }

        &:last-child {
          margin-top: 16px;

          @media @screen767 {
            margin-top: 0;
          }
        }
      }
    }

    .fvc-description {
      width: 100%;

      @media @screen767 {
        max-width: 100%;
        margin-top: -146px;
        position: relative;
      }

      .ant-badge {
        margin-bottom: 12px;

        @media @screen767 {
          margin-bottom: 8px;
        }
      }

      .heading {
        .display-h2;
        letter-spacing: 0.01em;
        color: @tq-white-90;

        @media @screen767 {
          margin-bottom: 8px;
        }
      }

      .sub-heading {
        .text-small-bold;

        @media @screen767 {
          .text-lg-bold;
        }
      }

      .date {
        .text-small;
        .text-white-70;
        margin-bottom: 7px;

        @media @screen767 {
          .text-md;
        }
      }

      .description {
        .paragraph-m;
        margin-bottom: 12px;
      }
    }
  }
}

.featured-carousel {
  // padding: 32px 0;

  @media @screen767 {
    padding: 0;
    background: @tq-neutral-1000;
  }

  .display-h4 {
    position: relative;

    @media @screen767 {
      color: @tq-neutral-100;
      margin-bottom: 12px;
      position: relative;
      z-index: 1;
    }
  }

  &.center-false {
    position: relative;

    @media @screen767 {
      overflow: hidden;
      padding: 24px 16px 0 16px;
    }

    &:before {
      @media @screen767 {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        // background-image: url(/images/blur.png);
        filter: blur(80px);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    &:after {
      @media @screen767 {
        content: '';
        background: rgba(0, 0, 0, 0.65);
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
      }
    }

    .ant-carousel {
      position: relative;
      z-index: 1;
    }

    .fvc-image {
      @media @screen767 {
        height: 0;
        padding-top: 56%;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        z-index: 1;
      }

      &::before {
        display: none;
      }
    }

    .slick-dots {
      @media @screen767 {
        background: transparent;
        padding: 0;
      }
    }

    .fvc-details {
      @media @screen767 {
        background: transparent;
        padding: 16px 0;
      }

      .fvc-description {
        @media @screen767 {
          margin-top: 0;
        }
      }
    }

    .ant-carousel {
      .slick-arrow {
        &.slick-prev {
          left: -20px;
        }

        &.slick-next {
          right: -20px;
        }
      }
    }
  }

  .slick-slide {
    @media @screen767 {
      margin: 0;
    }

    .featured-video-card {
      opacity: 0.4;
      -webkit-transform: scale(0.8682);
      -ms-transform: scale(0.8682);
      transform: scale(0.8682);
      -webkit-transform-origin-x: 89%;
      transition: transform 0.3s ease;
      padding-top: 40.9%;

      @media @screen767 {
        padding-top: 0;
        opacity: 1;
        transform: scale(1);
        -webkit-transform-origin-x: unset;
      }

      .paragraph-m {
        display: none;

        @media @screen767 {
          display: block;
        }
      }
    }

    &.slick-current {
      opacity: 1;

      .featured-video-card {
        opacity: 1;
        transform: scale(1);

        @media @screen767 {
          opacity: 1;
        }

        .paragraph-m {
          display: block;
        }

        .fvc-details .fvc-description .date {
          margin-bottom: 4px;
        }
      }

      + .slick-slide {
        .featured-video-card {
          -webkit-transform-origin-x: 10%;

          @media @screen767 {
            -webkit-transform-origin-x: unset;
          }
        }
      }

      .featured-video-card {
        padding-top: 40.644%;

        @media @screen767 {
          padding: 0;
        }

        .fvc-details {
          @media @screen767 {
            padding-bottom: 0;
          }
        }
      }
    }
  }

  .slick-dots {
    position: relative;
    bottom: 0;
    padding-bottom: 16px;
    background: @tq-neutral-1000;

    li {
      width: auto;
      margin: 0 2px;
      height: auto;

      &.slick-active {
        width: auto;

        button {
          background: @tq-neutral-0;
        }
      }

      button {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: @tq-neutral-700;
      }
    }
  }

  .ant-carousel {
    .slick-arrow {
      height: 48px;
      width: 48px;
      border-radius: 50%;
      background-color: @tq-neutral-900;
      box-shadow: 0px 45px 18px rgba(78, 68, 54, 0.02),
        0px 25px 15px rgba(78, 68, 54, 0.08),
        0px 11px 11px rgba(78, 68, 54, 0.13), 0px 3px 6px rgba(78, 68, 54, 0.15),
        0px 0px 0px rgba(78, 68, 54, 0.15);
      border-radius: 50%;
      background-position: center;
      background-repeat: no-repeat;
      transform: translateY(-34%);

      &:hover {
        background-color: @tq-black-80;
      }

      &.slick-prev {
        left: 32px;
        z-index: 1;
        background-image: url('../../../../src/assets/images/slick-left.svg');
      }

      &.slick-next {
        right: 32px;
        background-image: url('../../../../src/assets/images/slick-right.svg');
      }
    }
  }
}

.featured-banner-wrapper {
  display: flex;
  flex-wrap: wrap;
  background: @tq-neutral-1000;

  @media @screen767 {
    background: @tq-blue-2;
  }

  .featured-banner-grid {
    flex: 1;

    &.m-hide {
      &::before {
        content: '';
        position: absolute;
        right: 0;
        width: 60%;
        height: 100%;
        background: linear-gradient(
          270deg,
          #000000 -8.03%,
          rgba(0, 0, 0, 0) 100%
        );
        z-index: 1;
      }
    }

    .featured-banner-content {
      padding: 77px 0;
      color: @tq-neutral-0;
      max-width: 400px;
      margin-left: 80px;

      @media @screen767 {
        padding: 56px 16px;
        color: @tq-neutral-1000;
        max-width: 100%;
        margin-left: 0;
        text-align: center;
      }

      .tag_grid {
        @media @screen767 {
          justify-content: center;
        }
      }

      .heading {
        font: @tq-display-h2;
        letter-spacing: 0.01em;
        .mb-8;
        .mt-12;
      }

      .subheading {
        font: @tq-text-md-bold;
        .mb-8;
      }

      .button-wrapper {
        display: flex;
        gap: 8px;
        .mt-16;
      }
    }
  }
}

.featured-collection {
  .featured-collection-wrapper {
    display: flex;
    border-radius: 16px;
    overflow: hidden;

    @media @screen767 {
      flex-direction: column;
      padding: 32px 24px;
      border-radius: 0;
    }

    .featured-collection-img {
      padding-top: 25.596%;
      width: 48.81%;
      flex-shrink: 0;

      @media @screen767 {
        width: 100%;
        padding-top: 55.05%;
        border-radius: 8px;
        overflow: hidden;
      }
    }
  }

  .featured-collection-dec {
    padding: 64px 20px 64px 60px;
    max-width: 564px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .text-n-200;

    @media @screen991 {
      padding: 64px 20px 64px 30px;
      max-width: 100%;
    }

    @media @screen767 {
      padding: 24px 0 0 0;
    }

    .title {
      .display-h2;
      .mb-4;

      @media @screen767 {
        .display-h3;
        margin-bottom: 2px;
      }
    }

    .featured-collection-video-count {
      .text-small;
      .mb-4;

      @media @screen767 {
        .text-md;
        margin-bottom: 2px;
      }
    }

    p {
      .paragraph-l;

      @media @screen767 {
        .paragraph-m;
      }
    }

    button {
      margin-top: 32px;
      padding: 0 32px;

      @media @screen767 {
        margin-top: 24px;
        width: 100%;
      }
    }
  }
}

.featurecollection-slider {
  .slick-dots {
    bottom: 16px;

    li {
      width: auto;

      &.slick-active {
        width: auto;
      }

      button {
        height: 8px;
        width: 8px;
        background: @tq-neutral-700;
        margin: 0;
        border-radius: 50%;
      }
    }
  }

  .slick-arrow {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: @tq-neutral-200;
    z-index: 1;

    &.slick-next {
      right: 30px;
      background-image: url(../../../../src/assets/images/slick-btn.svg);
      background-repeat: no-repeat;
      background-size: contain;

      @media @screen1150 {
        right: 5px;
      }
    }

    &.slick-prev {
      left: 30px;
      transform: scale(-1);
      background-image: url(../../../../src/assets/images/slick-btn.svg);
      background-repeat: no-repeat;
      background-size: contain;

      @media @screen1150 {
        left: 5px;
      }
    }
  }
}
